@import "../site-vars.less";

.leaflet-popup {
  .leaflet-popup-tip {
    width: 14px;
    height: 14px;
  }

  .leaflet-popup-content-wrapper {
    border-radius: 0;

    .leaflet-popup-content {
      margin: 0.833em 1em;

      color: @textColor;
      font-family: @page-font;
      font-weight: @normal;
      font-size: 13px;
      line-height: 1.4;

      a {
        color: @nd-blue;
        &:hover {
          color: #006274;
        }
      }

      .title {
        margin-right: 6px;
        font-weight: 500;
      }
    }
  }
}

.leaflet-marker-icon.marker-cluster {
  background-color: white;
  color: @textColor;
  line-height: 33px - 6px;
  text-align: center;
  border-radius: 100px;
  border: @grey 3px solid;
  font-family: @page-font;
  font-size: 13px;

  &:hover {
    border-color: @greyHover;
  }
  &.active {
    border-color: @nd-blue;
  }
}

#search-map {
  height: 300px;
  margin-bottom: 0;
}
.horizontal-map {
  height: 350px;
  z-index: 5;
}
@media only screen and (min-width: @tabletBreakpoint) {
  #search-map {
    height: 450px;
  }
}
@media only screen and (min-width: @computerBreakpoint) {
  #search-map {
    height: 600px;
  }
  .horizontal-map {
    height: 420px;
  }
}
@media only screen and (min-width: @largeMonitorBreakpoint) {
  .horizontal-map {
    height: 500px;
  }
}

blockquote {
  font-size: 15px;
}
.quote-image {
  display: block;
  width: 180px;
  height: 248px;
  margin: 10px auto;
}
@media (min-width: 400px) {
  .quote-image {
    float: right;
    margin: 0 0 0 25px;
  }
}

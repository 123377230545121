.ui.popup {
  & > .content {
    font-weight: @normal;
  }

  & > .ui.divider {
    margin-top: 0.5em;
  }

  & > a {
    display: inline-block;
    margin-top: 0.5em;

    & + a {
      margin-left: 0.5rem;
    }

    i.icon {
      margin: 0 0 0 0.25rem;
    }
  }
}

.ranking-cell {
  display: grid;
  column-gap: 0.4em;
  grid-template-columns: auto 1fr;

  i {
    margin: 0;
  }
}

#innovation-ranking-menu {
  margin-bottom: 14px;
  border-right-color: transparent;
  border-left-color: transparent;
}

@import "../../site-vars.less";

.ui.insolvencies.feed {
  display: flex;
  flex-wrap: wrap;
  & > .event {
    flex: 0 0 100%;
    @media (min-width: @tabletBreakpoint) {
      flex-basis: 50%;
    }
    @media (min-width: @largeMonitorBreakpoint) {
      flex-basis: 33.3%;
    }
    &.active,
    &:hover {
      background-color: @nd-grey;
    }
    & > .label {
      margin: 0.25em 0 0.25em 0.25em;
    }
    & > .content .extra.text {
      margin-top: 0;
    }
  }
}

/*
 *  Visualization basic styles 
 */

@non-hover-fill-opacity: 0.9;
@axis-color: rgba(0, 0, 0, 0.6);
@axis-font-size: 0.9em;
@transition-duration: 0.25s;
@transition-timing: ease-in-out;

.text-shadow-on-color() {
  //text-shadow: 1px 1px 3px #444;
}

.transition(@properties) {
  transition-property: @properties;
  transition-duration: @transition-duration;
  transition-timing-function: @transition-timing;
}
.user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: moz-none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.axis-style() {
  stroke-width: 2;
  stroke: @axis-color;
  stroke-opacity: 0.75;
  fill: transparent;
}
.axis-text-style {
  font-size: @axis-font-size;
  font-family: inherit;
  fill: @axis-color;
}

[data-layout="barChart"] {
  .domain {
    .axis-style();
  }
  .tick text {
    .axis-text-style() !important;
  }
  .tick line {
    stroke: rgba(0, 0, 0, 0.1);
    shape-rendering: crispEdges;
  }
  rect {
    .transition(fill);
    fill-opacity: @non-hover-fill-opacity;
  }
  rect:hover {
    fill-opacity: 1;
  }
}

body > .tooltip {
  background-color: white;
  border: 1px solid #1b1c1d;
  padding: 0.5em;
  width: 25em;
  white-space: normal;
  &.ui.label {
    line-height: 130%;
    font-size: 100% / 1.07;
    color: #000;
  }
  small {
    display: block;
    margin-top: 0.1em;
    margin-bottom: 0;
    line-height: 110%;
  }
  span {
    display: inline-block;
    margin: 2px 0;
  }
  .name {
    &:after {
      content: ":";
    }
    margin-right: 1ex;
  }
  .source {
    display: block;
  }
  .note {
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
    display: block;
  }
}

.bizq-divider {
  display: table;
  white-space: nowrap;
  height: auto;
  line-height: 1;
  text-align: center;
  margin: 0 0 1em 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 130%;

  &:before,
  &:after {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC");
  }

  @horizontalDividerMargin: 1em;

  &:before {
    background-position: right @horizontalDividerMargin top 50%;
  }
  &:after {
    background-position: left @horizontalDividerMargin top 50%;
  }
}

[data-layout="vita"] {
  @root: @viz-base-color1;
  @highlight: @viz-base-color2;
  @neutral: @viz-base-color3;
  @color: @neutral;
  a {
    fill-opacity: @non-hover-fill-opacity;
    cursor: pointer;
  }
  a:hover {
    fill-opacity: 1;
  }
  polygon,
  rect {
    fill: @color;
    &.c10 {
      fill: @root;
    }
  }
  [data-old="true"] {
    polygon,
    rect {
      fill: @viz-grey;
    }
  }
  [data-warn="true"] {
    polygon,
    rect {
      fill: @viz-orange;
    }
  }
  .label-text {
    fill: white;
    text-anchor: middle;
  }
  .extra {
    &.left {
      text-anchor: end;
      transform: translateX(-0.3em);
    }
    &.right {
      text-anchor: start;
      transform: translateX(0.3em);
    }
  }
  line.today {
    stroke: @viz-grey;
    stroke-width: 2;
    stroke-dasharray: 2 2;
  }
}

[data-layout="history"],
[data-layout="vita"] {
  font-size: @axis-font-size * 0.9;
  .tick text {
    .axis-text-style();
  }
  .tick line,
  .domain {
    .axis-style();
  }
  .link-layer path[data-highlighted="true"] {
    stroke-width: 3;
  }
  .label-g {
    &[data-clickable="true"] a,
    a[*|href] {
      cursor: pointer;
      text {
        cursor: pointer;
      }
    }
    text {
      .user-select-none();
    }
    a {
      cursor: default;
      text:hover {
        .text-shadow-on-color;
      }
      rect {
        .transition(fill);
      }
    }
  }
  .grid path {
    display: none !important;
  }
}

[data-layout="drillDown"] {
  .root {
    position: relative;
    height: 100%;
  }
  * {
    box-sizing: border-box;
  }
  .legends {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px 2px 0 2px;
    font-size: 90%;
    .legend1 {
      font-weight: bold;
    }
    .legend2 {
    }
    .legend3 {
      float: right;
    }
  }
  .box-parent {
    position: absolute;
    top: 0px;
    bottom: 2em;
    left: 0px;
    right: 0px;
    .box {
      .transition(all);
      overflow: hidden;
      position: absolute;
      padding: 0.3rem;
      color: white;
      font-size: 0.7rem;
      line-height: 1.2;
      text-indent: 2px;
      .value,
      .desc {
        .text-shadow-on-color;
      }
      .value {
        font-weight: bold;
        font-size: 0.8rem;
      }
      &[data-neg="true"] {
        .value,
        .desc {
          color: pink;
        }
      }
      &[data-small="true"] {
        .value,
        .desc {
          display: none;
        }
      }
    }
    &.highlighting {
      .box {
        //opacity: 0.8;
        &.highlighted {
          opacity: 1;
        }
        &:hover {
          transform: scale(1.05);
          z-index: 100;
          border: 1px solid white;
        }
      }
    }
  }
}

.linkColorScheme(@color, @highlight-color) {
  .line,
  .head,
  .tail {
    stroke: @color;
    .transition(stroke);
  }
  .head,
  .tail {
    fill: @color;
    .transition(fill);
  }
  .desc {
    fill: @color;
  }
  &:hover {
    .desc {
      fill: white;
    }
    .desc-bg {
      fill: @highlight-color;
      opacity: 0.9;
    }
  }
}

.nodeColorScheme(@color, @highlight-color) {
  circle {
    fill: @color;
  }
  .text {
    fill: @color;
  }
  .text-bg {
    fill: @color;
    opacity: 0;
  }
  circle,
  .text,
  .text-bg {
    transition-property: fill, opacity;
    transition-timing-function: ease-out;
    transition-duration: 0s;
  }
  &:hover {
    circle {
      fill: @highlight-color;
    }
    .text {
      fill: white;
    }
    .text-bg {
      fill: @highlight-color;
      opacity: 1;
    }
  }
}

[data-layout="graph"],
[data-layout="dendrogram"] {
  svg {
    &:hover {
      overflow-x: visible !important;
    }
    @root: @viz-base-color1;
    @highlight: @viz-base-color2;
    @neutral: @viz-base-color3;

    .legend {
      line {
        stroke: @neutral;
        stroke-width: 2;
        &[data-old="true"] {
          stroke: @viz-grey;
        }
        &[data-warn="true"] {
          stroke: @viz-orange;
        }
      }
      text {
        font-size: 0.8rem;
        font-weight: normal;
        text-anchor: start;
        fill: @neutral;
        &[data-old="true"] {
          fill: @viz-grey;
        }
        &[data-warn="true"] {
          fill: @viz-orange;
        }
      }
    }

    .link {
      .linkColorScheme(@neutral, @highlight);
      .line {
        stroke-width: 3;
        fill: transparent;
      }
      .desc {
        opacity: 0;
        //.transition(opacity);
        .user-select-none();
        font-size: 0.7rem;
      }
      .desc-bg {
        fill: white;
        opacity: 0;
      }
      .head,
      .tail {
        display: none;
      }
      &[data-head] {
        .head {
          display: inherit;
        }
      }
      &[data-tail] {
        .tail {
          display: inherit;
        }
      }
      &[data-old="true"] {
        .linkColorScheme(@viz-grey, @viz-grey-hl);
      }
      &[data-warn="true"] {
        .linkColorScheme(@viz-orange, @viz-orange-hl);
      }
      &[data-highlight="root"],
      &[data-highlight="node"] {
        .line {
          stroke-width: 4;
        }
        .desc {
          opacity: 1;
          font-weight: bold;
        }
        &[data-old="true"] {
          .linkColorScheme(@viz-grey-hl, @viz-grey-hl);
        }
        &[data-warn="true"] {
          .linkColorScheme(@viz-orange-hl, @viz-orange-hl);
        }
        .desc-bg {
          opacity: 0.9;
        }
      }
      &:hover {
        // XXX
        .line {
          stroke-width: 4;
        }
        .desc {
          opacity: 1;
          font-weight: bold;
        }
      }
      &[data-highlight="root"] {
        .linkColorScheme(@root, @root);
      }
      &[data-highlight="node"] {
        .linkColorScheme(@highlight, @highlight);
      }
      &[data-helper] {
        display: none;
      }
    }
    a.node {
      cursor: default;
    }
    a.node[*|href],
    a[data-clickable="true"] {
      cursor: pointer;
      text {
        cursor: pointer;
      }
    }
    .node {
      .text,
      .text-bg {
        transform: translateY(36px);
        font-size: 0.8rem;
        text-anchor: middle;
      }
      .icon {
        transform: translateY(7px);
        font-family: icons;
        font-size: 1.4rem;
        text-anchor: middle;
        fill: white;
      }
      .nodeColorScheme(@neutral, @highlight);
      &[data-old="true"] {
        .nodeColorScheme(@viz-grey, @viz-grey-hl);
      }
      circle[data-warn] {
        fill: white !important;
        &:hover {
          fill: white !important;
        }
      }
      text[data-warn] {
        transform: translate(20px, -15px);
        fill: @viz-orange;
        stroke: lightgray;
        stroke-width: 1;
        &:hover {
          fill: @viz-orange-hl;
        }
      }
      &[data-root="true"] {
        .text {
          font-weight: bold;
        }
        .nodeColorScheme(@root, @root);
      }
    }
    &[data-dense="true"] {
      .link {
        stroke-width: 1;
      }
      .node[data-leaf="true"] {
        circle {
          display: none;
        }
        .text,
        .text-bg {
          transform: translateY(3px) translateX(25px);
          text-anchor: start;
        }
        .icon {
          transform: translateY(4px) translateX(6px);
          font-size: 1rem;
          text-anchor: start;
          fill: @neutral;
        }
        &[data-old="true"] .icon {
          fill: @viz-grey;
        }
        &[data-warn="true"] .icon {
          fill: @viz-orange;
        }
      }
    }
  }
}

[data-layout="pubTable"],
[data-layout="searchResults"] {
  padding: 1em;
  @separation: 0.5em;

  h2 {
    font-size: 115%;
    margin-top: 0;
    margin-bottom: 0px;
    a {
      text-decoration: none;
    }
  }

  ul.search-results {
    list-style-type: none;
    padding-left: 0;
    margin: 0 0 @separation;
    li.search-result {
      padding-left: 0;
      .icon {
        display: block;
        float: left;
        font-size: 150%;
        margin-top: 0.1em;
        margin-right: 1ex;
      }
    }
  }

  .more {
    margin-top: 2 * @separation;
    a:not(:empty):after {
      margin-left: 0.15em;
      content: "►";
    }
  }

  .icon {
    font-family: icons;
    font-weight: normal;
  }

  .pub,
  .search-result {
    padding: @separation;
    .transition(all);
  }
  .pub {
    .icon {
      width: 1.2em;
      position: relative;
      text-align: center;
      display: inline-block;
    }
    .date {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
    .text {
      margin-top: @separation;
      .lead {
        text-decoration: underline;
        font-weight: bold;
      }
      .company {
        font-weight: bold;
      }
      .person {
        font-weight: bold;
      }
      address {
        display: inline;
      }
    }
    h2 a:after {
      margin-left: 0.15em;
      font-size: 75%;
      content: "►";
    }
    .text {
      display: none;
    }
    &.expanded {
      h2 a:after {
        content: "▼";
      }
      .text {
        display: inherit;
      }
    }
  }
}

.snippets .snippet {
  h4 {
    margin: 0;
  }
  table td,
  table th {
    padding: 0 0.5em 0 0;
    p {
      padding: 0;
      margin: 0;
    }
  }
}

[data-layout="sheet"] {
  .ui.bizq.table tbody tr {
    td:not(:first-child) {
      text-align: right;
    }
    td:first-child {
      min-width: 20em;
      & when not (@tabletBreakpoint = false) {
        @media (max-width: @tabletBreakpoint - 0.02px) {
          min-width: 12em;
        }
      }
    }
  }
}

[data-layout="financials"] {
  .ui.bizq.table tbody tr {
    td:first-child {
      min-width: 12em;
    }
  }
  thead th:first-child {
    text-transform: uppercase;
  }
}

[data-layout="sheet"],
[data-layout="financials"] {
  a[download] {
    float: right;
    .icon.download {
      &:before {
        content: "\f019";
        font-style: normal;
        font-weight: normal;
      }
      font-family: icons;
      font-size: 1.3em;
    }
  }
  .note {
    display: block;
    white-space: normal !important;
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }
  .ui.bizq.table {
    .hidden {
      display: none;
    }
    thead,
    tfoot {
      text-align: center;
      th {
        &:first-child {
          text-align: left;
        }
      }
    }
    tfoot {
      th {
        &:not(:first-child) {
          font-size: 95%;
        }
      }
      th {
        &:first-child {
          //	text-align: right;
        }
      }
    }

    tbody {
      .expanded,
      .closed {
        cursor: default;
        &[data-child-count] {
          cursor: pointer;
        }
        td:first-child:after {
          font-size: 75%;
          margin-left: 0.25em;
        }
      }

      .closed[data-child-count] td:first-child {
        &:after {
          content: "►";
        }
      }

      .expanded[data-child-count] td:first-child:after {
        content: "▼";
      }

      tr {
        cursor: default;

        td {
          white-space: nowrap;

          .auditor {
            white-space: normal;
          }

          &:not(:first-child) {
            // left is much less, so removing this:
            // padding-right: 1em;
          }
          &:first-child {
            white-space: normal;
            text-align: left;
          }
        }

        &[data-level="0"] td:first-child {
          font-weight: bold;
        }

        &[data-level="2"] td:first-child {
          padding-left: 1.5em;
        }

        &[data-level="3"] td:first-child {
          padding-left: 3em;
        }

        &[data-level="4"] td:first-child {
          padding-left: 4.5em;
        }

        &[data-level="5"] td:first-child {
          padding-left: 6em;
        }

        &[data-level] td:first-child {
          //padding-left: 6em;
        }

        &[data-summary="true"] {
          td {
            border-top-width: 3px;
          }
          td:first-child {
            font-weight: bold;
          }
        }
      }
    }
  }
}

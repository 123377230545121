@import "../site-vars.less";

@media only screen and (max-width: (@largestTabletScreen)) {
  .tablet.or.less.hidden {
    display: none;
  }
}

@media only screen and (max-width: (@largestMobileScreen)) {
  .mobile.hidden {
    display: none;
  }
}

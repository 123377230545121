.ui.grid.original-documents {
  .column:last-of-type {
    display: flex;
    justify-content: center;

    @media (min-width: @computerBreakpoint) {
      body[data-ads="false"] & {
        margin-top: 0.5rem;
      }
    }
  }

  [data-ads="true"] & {
    @media (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
      .column {
        width: 100% !important;
      }
    }
  }
}

.shop-banner {
  @hover-duration: 0.6s;
  @animation-duration: 8s;

  position: relative;
  width: 36rem;
  max-width: 100%;
  height: 100%;
  padding: 3px;
  background-color: @nd-blue;
  overflow: hidden;

  > .content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: url("@{version-prefix}/img/premium/banner.jpg");
    background-color: @nd-blue;
    background-repeat: no-repeat;
    background-position: 50% 90%;
    background-size: cover;
    overflow: hidden;

    position: relative;
    height: 100%;
    padding: 0.5rem 2.5rem;
    font-size: 2.2rem;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
    transition: all @hover-duration;
    transition-property: font-size;

    > span {
      left: 0;
      transform: translateX(0);
      transition:
        left @hover-duration,
        transform @hover-duration;
    }

    > .ui.image {
      margin-right: -1rem;
      opacity: 1;
      left: 0;
      transform: translateX(0);
      transition:
        opacity @hover-duration ease-in,
        left @hover-duration ease-in,
        transform 0.1s ease-out;
    }
  }

  [data-ads="true"] & {
    @media (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
      width: 28rem;
    }
  }
  @media (max-width: @largestMobileScreen) {
    width: 28rem;
  }
  @media (max-width: 450px) {
    width: 20rem;

    > .content {
      padding: 0.5rem 1.5rem;
    }
  }

  &::before {
    content: "";

    position: absolute;
    width: 200%;
    height: 60%;
    top: 20%;
    left: -50%;
    background: #fff;

    filter: blur(6px);
    -webkit-animation: rotating @animation-duration linear infinite;
    -moz-animation: rotating @animation-duration linear infinite;
    -ms-animation: rotating @animation-duration linear infinite;
    -o-animation: rotating @animation-duration linear infinite;
    animation: rotating @animation-duration linear infinite;

    transition: opacity @hover-duration ease-in-out;
  }

  a:hover &,
  a:focus & {
    .content {
      font-size: 2.8rem;

      > span {
        left: 50%;
        transform: translateX(-50%);
      }

      > .ui.image {
        opacity: 0;
        left: 100%;
        transform: translateX(-50%);
      }
    }

    &::before {
      opacity: 0.5;
    }
  }

  // Light reflection effect
  &::after {
    content: "";
    position: absolute;
    top: -250%;
    left: -150%;
    width: 150%;
    height: 400%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(255, 255, 255, 0.13) 33%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &.animated::after {
    opacity: 1;
    top: -50%;
    left: 120%;

    transition-property: left, top, opacity;
    transition-duration: 1s, 1s, 0.15s;
    transition-delay: 1s;
    transition-timing-function: ease;
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

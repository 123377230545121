.ranking-winners.ui.card {
  .content {
    &:first-child {
      flex-grow: 0;

      .header {
        margin-top: 1px;
      }
    }

    i {
      margin-right: 14px;
    }

    .large.header {
      text-transform: uppercase;
    }
  }
}

@keyframes blink {
  from {
    font-family: outline-icons;
    color: white;
  }
  to {
    font-family: icons;
    color: hsl(45, 100%, 85%);
  }
}

.outline.lightbulb.icon {
  animation-name: blink;
  animation-delay: 0.2s;
  animation-duration: 0.6s;
  animation-iteration-count: 2;
  animation-timing-function: linear;
  animation-play-state: paused;
}

.outline.lightbulb.icon.visible,
.outline.lightbulb.icon:hover {
  font-family: icons;
  color: hsl(45, 100%, 85%);
}

@import "~@fontsource/lato/400.css";

.nd-banner-link {
  text-decoration: none !important;
}

.nd-banner {
  @df-blue: #024ec1;
  @df-yellow: #ffc940;

  // horizontal size limits
  @heightHorizontal: 120px;
  @minWidthHorizontal: 150px;
  @minHeightHorizontal: 120px;
  @boxPaddingHorizontal: 0 2%;
  @rowGapHorizontal: 5%;

  // vertical size limits
  @heightVertical: 600px;
  @minWidthVertical: 100%;
  @minHeightVertical: 600px;
  @boxPaddingVertical: 20% 5%;
  @rowGapVertical: 5%;

  @textColor: #ffffff;
  @bannerBase: "@{version-prefix}/img/banner";

  display: flex;

  color: @textColor;
  background-size: cover;
  font-family: "Lato", sans-serif;
  font-size: 1.1rem !important;

  &:hover .button {
    background-color: #ffffff !important;
  }

  &:hover .box-center div img,
  &:hover .box-top .box-3 img {
    transform: scale(1.05);
  }

  .flexJustifyContent {
    display: flex;
    justify-content: center;
  }

  .buttonStyle(@fontSize) {
    border-radius: 0.6rem;
    background-color: @df-yellow;
    white-space: nowrap;
    color: @df-blue;
    div {
      font-size: @fontSize;
    }
  }

  &.horizontal {
    flex-direction: row;
    justify-content: space-evenly;
    height: @heightHorizontal;
    min-height: @minHeightHorizontal;
    min-width: @minWidthHorizontal;
    background-image: url("@{bannerBase}/dealfront/dealfront-banner-bg-horizontal.png");

    .box-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: @boxPaddingHorizontal;
      gap: @rowGapHorizontal;

      /* left logo container */

      div:nth-child(1) {
        .flexJustifyContent;

        img {
          max-height: @heightHorizontal - 90%;
          width: 100%;
        }
      }

      /* left logo container */

      div:nth-child(2) {
        .flexJustifyContent;
        text-align: center;
      }
    }

    .box-center {
      display: flex;
      align-items: center;
      padding: @boxPaddingHorizontal;

      div {
        img {
          max-height: @heightHorizontal - 50%;
          width: 100%;
          transition: transform 0.2s;
        }
      }
    }

    .box-right {
      .flexJustifyContent;
      flex-direction: column;
      padding: @boxPaddingHorizontal;
      gap: @rowGapHorizontal;

      /* right button claim */

      div:nth-child(1) {
        .flexJustifyContent;
        text-align: center;
      }

      /* right button container */

      div:nth-child(2) {
        .flexJustifyContent;

        .button {
          .buttonStyle(1em);
          padding: 0.5rem 1.2rem;
        }
      }
    }

    .stackedLayout(@logoWidth1: 55%, @logoWidth2: 70%) {
      @verticalMargin: 2%;

      height: auto;
      flex-direction: column;
      padding: 5% 0;

      .box-left {
        gap: 0;

        div {
          margin: @verticalMargin 0;
          // ECHOBOT LOGO
          img {
            max-height: unset !important;
            width: @logoWidth1 !important;
          }
        }
      }

      .box-center {
        div {
          display: flex;
          justify-content: center;
          margin: @verticalMargin 0;

          img {
            max-height: unset !important;
            width: @logoWidth2 !important;
          }
        }
      }

      .box-right {
        gap: 0;

        div {
          margin: @verticalMargin 0;
        }
      }
    }

    // STACKED COMPACT
    @media screen and (max-width: 500px) {
      .stackedLayout(55%, 70%);
    }
  }

  &.vertical {
    flex-direction: column;
    justify-content: space-between;
    height: @heightVertical;
    min-height: @minHeightVertical;
    min-width: @minWidthVertical;
    padding: @boxPaddingVertical;
    background-image: url("@{bannerBase}/dealfront/dealfront-banner-bg-vertical.png");

    .box-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //background: #ff0000;
      text-align: center;
      gap: 20%;

      .box-1 {
        .flexJustifyContent;
        //background: #1aa62a;
        img {
          width: 70%;
        }
      }

      .box-2 {
        font-size: 1.1em;
        margin: 10%;

        @media only screen and (max-width: 990px) {
          font-size: 0.8em;
        }
      }

      .box-3 {
        .flexJustifyContent;
        img {
          width: 90%;
          transition: transform 0.2s;
        }
      }
    }

    .box-bottom {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      text-align: center;
      justify-content: space-evenly;

      .box-1 {
        font-size: 1.7em;
        line-height: 1.3em;

        @media only screen and (max-width: 1200px) {
          font-size: 1.1em;
        }

        @media only screen and (max-width: 990px) {
          font-size: 0.8em;
        }
      }

      .box-2 {
        justify-content: center;
        .button {
          .buttonStyle(1.7em);
          padding: 1.2rem 1.2rem;

          @media only screen and (max-width: 1200px) {
            padding: 0.5rem 0.5rem;
            margin: 0 !important;
            div {
              padding: 0 !important;
              font-size: 1.1rem !important;
            }
          }

          @media only screen and (max-width: 990px) {
            //padding: .5rem 1rem;
            margin: 0 !important;
            div {
              padding: 0 !important;
              font-size: 0.8rem !important;
            }
          }
        }
      }
    }
  }

  &.horizontal.creditsafe {
    background-image: url("@{bannerBase}/creditsafe/creditsafe-banner-bg-horizontal.jpg");
    background-size: cover;
    background-position: center;
  }

  &.vertical.creditsafe {
    background-image: url("@{bannerBase}/creditsafe/creditsafe-banner-bg-vertical.jpg");
    background-size: cover;
    background-position: center;
  }

  &.horizontal.creditsafe .box-right div:nth-child(2) .button,
  &.vertical.creditsafe .box-bottom div:nth-child(2) .button {
    background-color: #ee2e24;
    color: #fff;
  }

  &.creditsafe:hover .button {
    background-color: #2c3e50 !important;
  }

  &.horizontal.creditsafe .box-left div:nth-child(2) {
    text-align: left !important;
  }
}

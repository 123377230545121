@premiumServicesMobileBreakpoint: 550px - 0.02px;

.premium-services {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  background: @nd-light-grey;

  @media (max-width: @premiumServicesMobileBreakpoint) {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media (min-width: @computerBreakpoint) {
    margin: 0 -2rem;
  }
  @media (max-width: @largestTabletScreen) {
    padding: 0 1rem;
  }
}

.premium-services__card {
  &.ui.card {
    margin: 0 2px;
    max-width: 300px;
    flex-grow: 1;
    text-wrap: balance;

    &.inverted {
      background-color: @nd-blue;

      &,
      .header,
      .content,
      .icon {
        color: @nd-white;
      }
      .content > .description {
        color: @nd-light-grey;
      }
      .meta {
        color: @nd-light-grey;
      }
      .extra {
        border-top-color: rgba(255 255 255 / 0.05) !important;
      }
    }

    @media (max-width: @largestTabletScreen) {
      &:not(.active) {
        display: none;
      }
    }

    > .content {
      flex-grow: 0;

      > .header {
        margin-top: 0.5rem;
      }
      &.extra {
        text-align: center;
      }
      &.title {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        border-top: 0 !important;
      }
      &.fill {
        flex-grow: 1;
      }
    }

    > .title + .extra.attribute {
      border-top: none !important;
      margin-top: 4rem;
    }
    > .title + .description {
      border-top: none !important;
      margin-top: calc(2rem + 11px);

      text-align: left;
      font-weight: @bold;

      p {
        margin-left: 0.5rem;
        text-wrap: initial;
      }
    }

    > .floating.label {
      top: -1.25em;
      right: 50%;
      margin: 0;
    }
  }

  .features {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    margin: 0.75rem 0;

    .icon {
      margin: 0;
    }

    > .all-features {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      > div {
        display: flex;
        gap: inherit;
      }
    }
  }

  .button {
    width: 100%;
  }

  .attribute {
    display: flex;
    align-items: center;

    > .icon {
      flex-shrink: 0;
      margin: 0 1.25rem 0 0.5rem;
    }

    > .content {
      color: @textColor;
      font-weight: @bold;
    }
  }

  .icon {
    width: 2rem;
    font-size: 2rem;
    color: @mutedTextColor;
  }
  svg {
    fill: @mutedTextColor;
  }
}

.premium-services__price {
  font-family: @header-font;
  font-weight: @header-font-weight;
  font-size: 2.6rem;
  margin: 0.5rem;
  margin-top: 1rem;
}

.premium-services__hidden {
  @media (min-width: @computerBreakpoint) {
    &:not(.content),
    > * {
      visibility: hidden;
    }
  }
  @media (max-width: @largestTabletScreen) {
    display: none;
  }
}

.premium-services__control {
  &.ui.card {
    @media (min-width: @computerBreakpoint) {
      display: none !important;
    }
    @media (max-width: @premiumServicesMobileBreakpoint) {
      max-width: unset;
      margin-bottom: 0.5rem;
      align-self: center;
    }

    align-self: start;
    margin: 0;
    margin-left: 2px;
    max-width: 200px;

    > .content > .header {
      margin-top: 0.5rem;
      text-align: center;
    }
  }
}

.premium-services__control-buttons {
  @media (max-width: @premiumServicesMobileBreakpoint) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
  }

  .ui.button {
    width: 100%;
    margin-bottom: 0.5rem;

    @media (max-width: @premiumServicesMobileBreakpoint) {
      white-space: nowrap;
      overflow: hidden;

      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    &.wide {
      grid-column: 1/-1;
    }
  }
}

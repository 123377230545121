@import "../site-vars.less";

table.dataTable.table thead {
  th.sorting,
  th.sorting_asc,
  th.sorting_desc {
    &:after {
      color: #6e6e6e;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

table.ui.table.dataTable.dt-spacing {
  // spacing is based on row padding, input padding, font size and input border
  margin-top: calc(2rem + 2 * 0.678614em + 1.2142em + 2px);
  margin-bottom: calc(1rem + 2 * 0.928571em + 1em + 2px);
}

.ui.menu {
  .paginate_button {
    &.previous,
    &.next {
      min-width: 0;
      padding-left: 0.9em;
      padding-right: 0.9em;
    }
    > i.icon {
      margin: 0;
    }
  }
}

table.static {
  th,
  td {
    &.static-hidden {
      display: none;
    }
  }
}

@media only screen and (max-width: (@largestMobileScreen)) {
  .dataTables_wrapper > .ui.stackable.grid > .row > .column {
    padding: 0 !important;
  }
}

// Selectable tables
table.dataTable.table {
  tr.selected {
    background-color: fade(@nd-blue, 10%);
  }

  th,
  td {
    > .ui.checkbox {
      label:empty {
        padding: 0;
      }
    }
  }
}

div.dataTables_wrapper {
  // Reduce padding after search and before pagination
  div.row {
    &.unpadded {
      &:first-child {
        padding-bottom: 0 !important;
      }

      &:last-child {
        padding-top: 0 !important;
      }
    }
    &:not(.dt-table) {
      &:first-child {
        padding-bottom: 0.5rem;
      }

      &:last-child {
        padding-top: 0.5rem;
      }
    }
  }

  .table-container() {
    background: @offWhite;
    padding: 0.5em 0.7em;
    border: 1px solid rgba(34, 36, 38, 0.1);
  }

  // Custom table-like container for length control and search
  .ui.grid .dt-header {
    .table-container();
    border-bottom: 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;

    .dataTables_length {
      margin-right: auto;
    }

    .ui.input,
    .ui.button {
      margin: 0;
    }
  }

  // Custom container for info and pagination
  .ui.grid .dt-pagination {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .dataTables_info {
      padding: 0 2px;
    }

    .dataTables_paginate {
      margin-left: auto;
    }

    @media (max-width: @largestMobileScreen) {
      flex-direction: column-reverse;

      .dataTables_info {
        margin-left: auto;
      }
    }
  }

  // Button container for selectable tables
  .ui.grid .dt-header {
    .button-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      gap: inherit;
    }
  }
}

[data-layout="netCompanyCount"] {
  display: flex;
  flex-direction: column;

  .legend {
    align-self: center;
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;

    & > svg {
      margin-right: 8px;
    }

    & > .definition {
      margin-right: 16px;
    }

    & > .definition:last-child {
      margin-right: 0;
    }
  }

  .ui.label {
    padding: 4px 8px;

    display: grid;
    grid-template-columns: auto auto auto;
    gap: 4px 8px;

    pointer-events: none;

    opacity: 0.9;

    h4 {
      grid-column: 2 / span 2;
      margin: 0;
      font-size: 12px;
      margin: 4px 0;
    }

    svg {
      align-self: center;
    }

    .definition {
      grid-column: 2;

      &:nth-of-type(2n) {
        margin-bottom: 4px;
      }
    }

    .value {
      justify-self: end;
    }
  }
}
